<template>
  <div>
    <div id="self-descriptors">
      <p class="descr-header">Self Descriptors</p>
      <div class="grid-3">
        <div v-for="descriptor in self" :key="descriptor.id">
          <p class="label">{{ `${descriptor.descriptorGeo} / ${descriptor.descriptorEng}` }}</p>
          <p class="value">{{ parseInt(descriptor.value) }}</p>
        </div>
      </div>
    </div>
    <hr />
    <div id="other-descriptors">
      <p class="descr-header">Other Descriptors</p>
      <div class="grid-3">
        <div v-for="descriptor in others" :key="descriptor.id">
          <p class="label">{{ `${descriptor.descriptorGeo} / ${descriptor.descriptorEng}` }}</p>
          <p class="value">{{ parseInt(descriptor.value) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("customersModule", ["customerDescriptors"]),
    self() {
      return this.customerDescriptors ? this.customerDescriptors.self : [];
    },
    others() {
      return this.customerDescriptors ? this.customerDescriptors.others : [];
    },
  },
};
</script>

<style scoped lang="scss">
.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.label {
  font-weight: bold;
  margin: 0;
  color: gray;
}

.descr-header {
  font-weight: bold;
  color: darkgray;
  text-align: center;
}
</style>
