<template>
  <div v-if="customer" class="grid-3">
    <div>
      <p class="label">{{ t.t("users.id") }}</p>
      <p class="value">{{ customer.displayId }}</p>
    </div>
    <div>
      <p class="label">{{ t.t("users.fullName") }}</p>
      <p class="value">
        {{ `${customer.firstName || ""} / ${customer.lastName || ""}` }}
      </p>
    </div>
    <div>
      <p class="label">{{ t.t("users.registrationDate") }}</p>
      <p class="value">{{ customer.registrationDate }}</p>
    </div>
    <div>
      <p class="label">{{ t.t("users.mobile") }}</p>
      <p class="value">{{ customer.mobile }}</p>
    </div>
    <div>
      <p class="label">{{ t.t("users.email") }}</p>
      <p class="value">{{ customer.email }}</p>
    </div>
    <div>
      <p class="label">{{ t.t("users.isRegistered") }}</p>
      <p class="value">
        {{
          customer.isRegistered
            ? t.t("users.registered")
            : t.t("users.notRegistered")
        }}
      </p>
    </div>
    <div>
      <p class="label">{{ t.t("users.receivedGiftQuantity") }}</p>
      <p class="value">{{ customer.receivedGiftQuantity }}</p>
    </div>
    <div>
      <p class="label">{{ t.t("users.sentGiftQuantity") }}</p>
      <p class="value">{{ customer.sentGiftQuantity }}</p>
    </div>
    <div>
      <p class="label">{{ t.t("users.revenueAmount") }}</p>
      <p class="value">{{ customer.revenueAmount }}</p>
    </div>
    <div>
      <p class="label">{{ t.t("users.dateOfBirth") }}</p>
      <p class="value">{{ customer.dateOfBirth }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("customersModule", ["customer"]),
    t() {
      return this.$i18n;
    },
  },
};
</script>

<style>
.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.label {
  color: gray;
  font-weight: bold;
  margin: 0;
}
</style>
