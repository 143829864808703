<template>
  <b-card
    :header="customer ? `${customer.firstName} ${customer.lastName}` : 'Customer Details'"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <div v-if="areActionsAllowed" class="d-flex justify-content-between mb-2">
      <BButton size="sm" variant="outline-primary" class="mr-1" @click="$router.push({ name: 'customers' })">
        <feather-icon icon="ArrowLeftIcon" class="mr-25" /> Go Back
      </BButton>
      <div>
        <BButton size="sm" variant="outline-danger" class="mr-1" @click="blockCustomerButton">{{
          t.t("global.block")
        }}</BButton>
        <BButton size="sm" variant="danger" @click="removeCustomerButton">{{ t.t("global.delete") }}</BButton>
      </div>
    </div>
    <CustomerDetails />
    <hr />
    <CustomerContactsTable />
    <hr />
    <CustomerFavoritesTable />
    <hr />
    <CustomerDescriptors />
  </b-card>
</template>

<script>
import { BCard, BButton } from "bootstrap-vue";
import { mapActions, mapState, mapMutations } from "vuex";
import Swal from "sweetalert2";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import isAllowed from "@core/utils/isAllowed";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CustomerContactsTable from "./Components/CustomerContactsTable.vue";
import CustomerFavoritesTable from "./Components/CustomerFavoritesTable.vue";
import CustomerDetails from "./Components/CustomerDetails.vue";
import CustomerDescriptors from "./Components/CustomerDescriptors.vue";

export default {
  name: "SingleCustomer",
  components: {
    BCard,
    BButton,
    CustomerContactsTable,
    CustomerFavoritesTable,
    CustomerDetails,
    FeatherIcon,
    CustomerDescriptors,
  },
  computed: {
    areActionsAllowed() {
      // import isAllowed from "@core/utils/isAllowed";
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Admin", "Editor", "Support"], roles);
    },
    t() {
      return this.$i18n;
    },
    ...mapState("customersModule", [
      "customer",
      "customers",
      "isTableBusy",
      "customerFavorites",
      "customerContacts",
      "customerDescriptors",
    ]),
    customerId() {
      return this.$router.currentRoute.params.id;
    },
  },
  mounted() {
    this.getCustomerDetails();
  },
  unMounted() {
    this.SET_CUSTOMER_CONTACTS(null);
    this.SET_CUSTOMER_FAVORITES(null);
    this.SET_CUSTOMER_DESCRIPTORS(null);
    this.SET_CUSTOMER(null);
  },
  methods: {
    ...mapMutations("customersModule", [
      "SET_CUSTOMER_CONTACTS",
      "SET_CUSTOMER_FAVORITES",
      "SET_CUSTOMER",
      "SET_CUSTOMER_DESCRIPTORS",
    ]),
    ...mapActions("customersModule", [
      "getCustomerById",
      "deleteCustomer",
      "blockCustomer",
      "getCustomerFavorites",
      "getCustomerContacts",
      "getCustomerDescriptors",
    ]),
    toast(title = "Success", variant = "succes") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          variant,
        },
      });
    },
    async getCustomerDetails() {
      this.getCustomerById(this.customerId);
      this.getCustomerFavorites(this.customerId);
      this.getCustomerContacts(this.customerId);
      this.getCustomerDescriptors(this.customerId);
    },
    swalConfirmation(type = "delete") {
      return Swal.fire({
        title: "Are you sure?",
        text: `You will ${type} this customer!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, ${type}!`,
      });
    },

    async blockCustomerButton() {
      const result = await this.swalConfirmation("block");
      if (result.isConfirmed) {
        try {
          await this.blockCustomer(this.customerId);
          this.toast("Customer blocked Successfully", "success");
          this.$router.push({ name: "customers" });
        } catch (error) {
          console.log(error);
        }
      }
    },
    async removeCustomerButton() {
      const result = await this.swalConfirmation();
      if (result.isConfirmed) {
        try {
          await this.deleteCustomer(this.customerId);
          this.toast("Customer Deleted Successfully", "success");
          this.$router.push({ name: "customers" });
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style></style>
