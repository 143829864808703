<template>
  <div>
    <p class="mb-0">Customers Favorites</p>
    <b-table
      sticky-header
      bordered
      hover
      responsive
      :fields="fields"
      class="shadow-sm rounded"
      thead-tr-class="order-main-thead"
      :items="customerFavorites"
      :busy="isTableBusy"
      primary-key="id"
      show-empty
      empty-text="No matching records found bro"
      small
    >
      <template #cell(nameGeo)="data">
        <div class="d-flex flex-column">
          <span>{{ data.item.nameGeo }}</span>
          <span>{{ data.item.nameEng }}</span>
        </div>
      </template>
      <template #cell(image)="data">
        <a :href="data.item.image" target="_blank">
          <img class="preview-image" :src="data.item.image" />
        </a>
      </template>
      <template #cell(gallery)="data">
        <a v-for="img of data.item.gallery" :key="img" :href="img" target="_blank">
          <img class="preview-image" :src="img" />
        </a>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  name: "CustomerFavoritesTable",
  components: {
    BTable,
  },
  computed: {
    ...mapState("customersModule", ["customerFavorites", "isTableBusy"]),
    fields() {
      return [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "nameGeo",
          label: this.$i18n.t("global.name"),
        },
        {
          key: "image",
          label: this.$i18n.t("users.favoriteImage"),
        },
        {
          key: "gallery",
          label: this.$i18n.t("users.favoriteGallery"),
        },
      ];
    },
  },
};
</script>

<style>
.preview-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 3px;
}
</style>
