<template>
  <div>
    <p class="mb-0">Customers Contacts</p>
    <b-table
      sticky-header
      bordered
      hover
      responsive
      :fields="fields"
      class="shadow-sm rounded"
      thead-tr-class="order-main-thead"
      :items="customerContacts"
      :busy="isTableBusy"
      primary-key="id"
      show-empty
      empty-text="No matching records found bro"
      small
    />
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  name: "CustomerContactsTable",
  components: {
    BTable,
  },
  computed: {
    ...mapState("customersModule", ["customerContacts", "isTableBusy"]),
    fields() {
      return [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "firstName",
          label: this.$i18n.t("users.firstName"),
        },
        {
          key: "lastName",
          label: this.$i18n.t("users.lastName"),
        },
        {
          key: "age",
          label: this.$i18n.t("users.age"),
        },
        {
          key: "gender",
          label: this.$i18n.t("users.gender"),
        },
        {
          key: "gender",
          label: this.$i18n.t("users.gender"),
        },
      ];
    },
  },
};
</script>

<style></style>
